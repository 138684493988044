import React from 'react'
import styled from 'styled-components'
import theme from '../theme'
import Block from './Block'

interface Props {
  background?: React.ReactNode
  children: React.ReactNode
  bigPadding?: boolean
}
/**
 * @deprecated For new pages use blocks/Hero
 */
export function Hero(props: Props) {
  const { background, children } = props
  return (
    <HeroContainer className="layout-index" {...props}>
      <div className={`hero ${props.bigPadding ? 'big-padding' : ''}`}>
        {background}
        <Block>{children}</Block>
      </div>
    </HeroContainer>
  )
}

const HeroContainer = styled.div<Props>`
  .hero {
    text-align: center;
    padding: 12px 0 32px;
    position: relative;
    z-index: 0;
    @media screen and (min-width: ${theme.breakpoints.medium}) {
      padding: 40px 0;
    }

    &.big-padding {
      padding-top: 64px;
    }
  }

  h1,
  p,
  h6 {
    color: ${props => (props.background ? 'white' : 'black')};
    margin-left: auto;
    margin-right: auto;
    max-width: 740px;
  }

  .icon {
    margin-bottom: 16px;
  }

  //waitlist-confirmation

  .overlay {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.35);
    z-index: 5;
    opacity: 0;
    transition: opacity 0.3s ease;

    &.show {
      opacity: 1;
    }
  }
`
