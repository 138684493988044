import React from 'react'
import styled from 'styled-components'
import theme from '../theme'

const TwoColumns = ({ children }) => {
  return <TwoColumnsContainer>{children}</TwoColumnsContainer>
}

export default TwoColumns

const TwoColumnsContainer = styled.div`
  .columns {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    flex-direction: row;
    gap: 2rem;

    .column {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      flex-direction: column;
      text-align: center;
      padding: 40px 24px;
      border-radius: ${theme.radii.extralarge};

      p,
      h4 {
        max-width: 400px;
      }
    }

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      .column {
        flex-basis: calc(50% - 1rem);
      }
    }
  }
`
