/* eslint-disable no-irregular-whitespace */
import React, { useRef } from 'react'
import styled from 'styled-components'
import Block from '../components/Block'
import theme from '../theme'
import Layout from '../components/Layout'
import TwoColumns from '../components/TwoColumns'
import { Hero } from '../components/Hero'
import VideoMp4 from '../assets/videos/swarmia-careers.mp4'
import VideoWebM from '../assets/videos/swarmia-careers.webm'
import swarmiaPng from '../assets/images/swarmia-careers.png'
import { SwarmiaBackgroundPattern } from '../components/SwarmiaBackgroundPattern'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import MetaImage from '../assets/images/careers-meta.png'
//perk icons
import Rocket from '../assets/images/icons/rocket-purple.svg'
import Coffee from '../assets/images/icons/coffee-purple.svg'
import Money from '../assets/images/icons/money-purple.svg'
import World from '../assets/images/icons/world-purple.svg'
import Tools from '../assets/images/icons/tools-purple.svg'
import Smile from '../assets/images/icons/smile-purple.svg'
import Health from '../assets/images/icons/health-purple.svg'
import Balloons from '../assets/images/icons/balloons-purple.svg'
import { useOpenJobsByDepartment } from '../hooks'

const Role = ({ title, location, link }) => {
  return (
    <RoleStyles>
      <a href={link} className="role-container">
        <h5>{title}</h5>
        <div className="role-details">
          <span className="role-location">{location}</span>
          <a href={link}>Apply</a>
        </div>
      </a>
    </RoleStyles>
  )
}

const Department = ({ title, children }) => {
  return (
    <DepartmentStyles>
      <Divider>{title}</Divider>
      {children}
    </DepartmentStyles>
  )
}

const Perk = ({ icon, children }) => {
  return (
    <PerkStyles>
      <img alt="" src={icon} width={32} height={32} />
      <div>{children}</div>
    </PerkStyles>
  )
}

const CareersVideo = () => {
  const videoRef = useRef<HTMLVideoElement>(null)

  if (videoRef) {
    void videoRef.current?.play()
  }
  return (
    <div
      style={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: '0',
        left: '0',
        zIndex: '-1',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          bottom: '0',
          right: '0',
          background: 'rgba(0,0,0,0.5)',
        }}
      />
      <video
        autoPlay
        muted
        loop
        className="hero-video"
        playsInline
        preload="metadata"
        ref={videoRef}
        poster={swarmiaPng}
        style={{
          height: '100%',
          width: '100%',
          objectFit: 'cover',
        }}
      >
        <source src={VideoMp4} type="video/mp4" />
        <source src={VideoWebM} type="video/webm" />
        <img
          src={swarmiaPng}
          alt="Join the Swarmia team in building the next-generation productivity platform for software teams"
          loading="lazy"
        />
      </video>
    </div>
  )
}

const Careers = () => {
  const openJobsByDepartment = useOpenJobsByDepartment()

  return (
    <Layout
      justifyCenter
      title="Join us"
      description="Swarmia is on a mission to build happier and more productive software teams. Come join us!"
      metaImage={MetaImage}
      variant="dark"
    >
      <Hero background={<SwarmiaBackgroundPattern />}>
        <Block type="padding-medium">
          <h6>Careers</h6>
          <h1
            style={{
              marginBottom: '2.5rem',
            }}
          >
            Join us in shaping the way great software teams work
          </h1>
          <button className="extra-large white">
            <a href="#open-positions">See open positions</a>
          </button>
        </Block>
        <CareersVideo />
      </Hero>
      <Block size="medium" type="padding-medium">
        <h2>
          We&#39;re on a mission to build happier
          <br />
          and more productive software teams
        </h2>
        <p>
          We know from experience that scaling a software development
          organization is hard. As your team grows, so does complexity. All of a
          sudden, you&#39;re making decisions based on limited information,
          juggling too much work without clear priorities, and accumulating more
          technical debt than you can handle.
        </p>
        <p>
          We&#39;re building Swarmia to help teams take back control. We give
          software engineers, as well as their managers and leaders, the
          insights and tools they need to make better decisions, improve team
          wellbeing, and ship faster — even as the organization grows.
        </p>
        <p>
          And because we know what it takes to build happy, high-performing
          teams, we do our best to practice what we preach. We&#39;re all about
          working closely with our customers and teammates, prioritizing the
          most important work, and getting a little bit better every day.
        </p>
        <p>
          To make this possible, we&#39;re building a diverse team of
          individuals who want to help some of the best software teams in the
          world get even better. Come join us!
        </p>
        <p>
          <a href="#open-positions">See open positions → </a>&nbsp; ·&nbsp;
          {'  '}
          <a href="/about-us#team">Meet the team</a>
        </p>
      </Block>
      <Block size="large" type="padding-large" centered>
        <BenefitsStyles>
          <TwoColumns>
            <h1>Perks & benefits</h1>
            <div className="columns two-columns">
              <div className="column">
                <Perk icon={Rocket}>Low-hierarchy, experienced team</Perk>
                <Perk icon={Coffee}>Flexible working hours</Perk>
                <Perk icon={Money}>Competitive salary and equity package</Perk>
                <Perk icon={World}>Work remotely or from our HQ</Perk>
              </div>
              <div className="column">
                <Perk icon={Tools}>Choose your own equipment</Perk>
                <Perk icon={Smile}>Parental and family leave</Perk>
                <Perk icon={Health}>Comprehensive health plan</Perk>
                <Perk icon={Balloons}>Regular team activites and events</Perk>
              </div>
            </div>
          </TwoColumns>
        </BenefitsStyles>
      </Block>
      {openJobsByDepartment && (
        <div
          id="open-positions"
          style={{
            background: '#F0F4FA',
          }}
        >
          <Block size="medium" type="padding-large">
            <h1
              style={{
                textAlign: 'center',
              }}
            >
              Open positions
            </h1>
            {Object.entries(openJobsByDepartment).length > 0 ? (
              Object.entries(openJobsByDepartment).map(([department, jobs]) => (
                <Department title={department} key={department}>
                  {jobs.map(job => (
                    <Role
                      key={job.id}
                      title={job.title}
                      location={job.location}
                      link={job.jobUrl}
                    />
                  ))}
                </Department>
              ))
            ) : (
              <p>
                We don&#39;t have any open positions at the moment. Still eager
                to join and think you&#39;d be a great match? Drop us a line at 
                <a href="mailto:hello@swarmia.com">hello@swarmia.com</a>.
              </p>
            )}
          </Block>
        </div>
      )}
      <FeaturedBlogArticles
        articles={[
          '/blog/building-software-the-swarmia-way/',
          '/blog/how-we-use-swarmia-at-swarmia/',
          '/blog/why-product-teams-should-plan-together/',
        ]}
        heading="LEARN MORE ABOUT THE WAY WE WORK"
      />
    </Layout>
  )
}

export default Careers

const Divider = styled.div`
  border-bottom: 2px black solid;
  font-size: ${theme.size[3]};
  text-align: left;
  padding-bottom: ${theme.space[1]};
  font-weight: bold;
  margin-bottom: ${theme.space[4]};
`

const RoleStyles = styled.div`
  border-radius: ${theme.radii.large};
  box-shadow: ${theme.shadows.soft};
  background: white;
  transition: transform 0.15s ease-out;
  margin-bottom: ${theme.space[6]};

  &:hover {
    transform: scale3d(0.98, 0.98, 0.98);
    transition: transform 0.15s ease-out;
  }

  .role-container {
    display: flex;
    flex-direction: column;
    padding: ${theme.space[5]} ${theme.space[5]};

    h5 {
      margin: 0;
    }

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      flex-direction: row;
      justify-content: space-between;
      padding: ${theme.space[7]} ${theme.space[7]};
      line-height: 1.3;
    }
  }

  .role-details {
    a {
      color: ${theme.colors.heading};
      font-weight: 600;
    }

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      text-align: right;
    }
  }

  .role-location {
    color: ${theme.colors.black500};
    margin-right: 24px;
    display: block;

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      display: inline;
    }
  }
`

const DepartmentStyles = styled.div``

const PerkStyles = styled.div`
  padding: 20px 0;
  width: 100%;
  max-width: 480px;
  line-height: 1.5;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  list-style: none;

  img {
    position: relative;
    margin-right: 12px;
    display: inline-block;
  }
`

const BenefitsStyles = styled.div`
    .columns {
        max-width:1000px;
        margin: auto;
    }

  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    .columns {
        gap:0;
    .column {
      padding-top: 0;
      padding-bottom:0;
    }
  }
`
