import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
/**
 * Requires that the parent has the CSS styles "position: relative;"
 */
export const SwarmiaBackgroundPattern = () => (
  <StaticImage
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: -1,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    }}
    src="../assets/images/decoration/swarmia-pattern.svg"
    aria-hidden="true"
    alt=""
    quality={90}
  />
)
